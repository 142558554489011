<template>
  <div class="home">
    <el-carousel trigger="click" height="400px" style="overflow: hidden">
      <el-carousel-item
        v-for="(item, index) in bannerList"
        :key="index"
        class="center"
      >
        <div class="banner-content" @click="bannerLink(item)">
          <!-- <div class="banner-content-item">
						<h1>{{ item.remark }}</h1>
						<div class="font-first">
							<div style="line-height: 20px; white-space: normal; width: 700px">
								{{ item.content }}
							</div>
						</div>
					</div> -->
          <img :src="item.image" class="banner-content-img" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <!--服务内容 start-->
    <div style="color: #545454; margin-left: 11%; margin-bottom: 30px">
      <div style="margin-top: 30px; font-size: 20px; font-weight: 100">
        <img
          :src="server_img[0].image"
          class=""
          style="width: 30px; height: 30px"
        />
        <span
          style="
            font-weight: 600;
            position: absolute;
            margin-left: 20px;
            margin-top: 8px;
          "
          >服务内容 —</span
        >
        <span
          style="
            position: absolute;
            right: 150px;
            margin-top: 15px;
            font-size: 15px;
          "
          >SERVICE CONTENT</span
        >
      </div>
    </div>
    <div class="home-seek between">
      <div
        class="seek-item seek-first align-between"
        :style="'background-image:url(' + first_image + ')'"
        @click="GoIPstorage"
      >
        <div class="seek-item-title">IP授权库</div>
      </div>
      <div
        class="seek-item seek-two align-between"
        :style="'background-image:url(' + two_image + ')'"
        @click="Goservice"
      >
        <div class="seek-item-title">IP服务</div>
      </div>
    </div>
    <!--服务内容 end-->
    <!--热门推荐 start-->
    <div class="home-recommend">
      <div style="color: #545454; margin-left: 11%; margin-bottom: 30px">
        <div style="margin-top: 30px; font-size: 20px; font-weight: 100">
          <img
            :src="server_img[1].image"
            class=""
            style="width: 30px; height: 30px"
          />
          <span
            style="
              font-weight: 600;
              position: absolute;
              margin-left: 20px;
              margin-top: 8px;
            "
            >热门推荐 —</span
          >
          <span
            style="
              position: absolute;
              right: 150px;
              margin-top: 15px;
              font-size: 15px;
            "
            >RECOMMENDATION</span
          >
        </div>
      </div>

      <div class="recommend-List center">
        <div
          class="recommend-item"
          v-for="(item, index) in sucaiList"
          :key="index"
          @click="sucaiDetail(item)"
        >
          <img :src="item.image" alt="" />
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!--热门推荐 end-->
    <!--IP學院 start-->
    <div class="home-college">
      <div style="color: #545454; margin-left: 11%; margin-bottom: 30px">
        <div style="font-size: 20px; font-weight: 100">
          <img
            :src="server_img[2].image"
            class=""
            style="width: 30px; height: 30px"
          />
          <span
            style="
              font-weight: 600;
              position: absolute;
              margin-left: 20px;
              margin-top: 8px;
            "
            >IP学院 —</span
          >
          <span
            style="
              position: absolute;
              right: 150px;
              margin-top: 15px;
              font-size: 15px;
            "
            >IP COLLEGE</span
          >
        </div>
      </div>

      <el-carousel
        trigger="click"
        height="280px"
        style="width: 100%; overflow: hidden"
      >
        <el-carousel-item
          style="display: flex; justify-content: center; padding-top: 10px"
        >
          <div class="college-List">
            <div
              class="college-progress-img"
              style="width: 210px"
              v-for="(item, index) in collegeList"
              :key="index"
              @click="schoolDetail(item.id)"
            >
              <div class="college-progress" style="">
                <img :src="item.image" alt="" style="" />
              </div>
              <div class="college-title">{{ item.user_name }}</div>
              <div class="college-content" v-html="item.content"></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="college-footer center">更多 ></div> -->
    </div>
    <!--IP學院 end-->
    <!--IP活動 start-->

    <div @click="huodong()">
      <el-carousel trigger="click" height="500px" style="overflow: hidden">
        <el-carousel-item
          v-for="(item, index) in footerListBann"
          :key="index"
          class="center"
        >
          <img
            :src="item.image"
            alt=""
            style="object-fit: cover; width: 100%"
          />
        </el-carousel-item>
      </el-carousel>
      <!-- <img src="" alt="" /> -->
    </div>
    <!--IP活動 end-->
    <!--中心动态 start-->
    <div class="home-news">
      <div style="color: #545454; margin-left: 0%; margin-bottom: 30px">
        <div style="margin-top: 40px; font-size: 20px; font-weight: 100">
          <img
            :src="server_img[3].image"
            class=""
            style="width: 30px; height: 30px"
          />
          <span
            style="
              font-weight: 600;
              position: absolute;
              margin-left: 20px;
              margin-top: 8px;
            "
            >中心动态 —</span
          >
          <span
            style="
              position: absolute;
              right: 150px;
              margin-top: 15px;
              font-size: 15px;
            "
            >CENTERL NEWS</span
          >
        </div>
      </div>
      <div class="activity-border" style="width: 1200px; height: 300px">
        <div
          class="flex"
          style="justify-content: center; border-right: 1px solid #"
        >
          <div class="news-left">
            <!-- <img :src="activityList[0].image" alt="" style="height:300px;" /> -->
            <el-carousel
              trigger="click"
              height="height:300px"
              style="overflow: hidden"
            >
              <el-carousel-item
                v-for="(item, index) in activityList"
                :key="index"
                class="center"
              >
                <img
                  :src="item.image"
                  alt=""
                  style="width: 100%; height: 300px; object-fit: cover"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div style="margin-left: 10px; width: 100%">
            <div style="margin-top: 19px">
              <div
                v-for="(item, index) in newsList"
                :key="index"
                class="between"
                v-bind:class="index == 0 ? 'newsitem1' : 'newsitem'"
                @click="newDetail(item)"
              >
                <div class="align-center">
                  <div
                    class="news-right-time"
                    style="
                      font-size: 18px;
                      color: rgb(0, 118, 254);
                      margin-bottom: 4px;
                    "
                    v-if="index == 0"
                  >
                    {{ item.title }}
                  </div>
                  <div style="font-size: 18px" v-else>{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="center"
        style="margin-top: 0px; float: right"
        @click="NEWList()"
      >
        <div class="college-footer center">更多 ></div>
      </div>
    </div>
    <!--中心动态 结束-->
    <!--平台优势 start-->
    <div class="home-superiority center-noflex">
      <!-- <div style="color: #545454; text-align: center; margin-bottom: 40px">
				<div style="margin-bottom: 8px; font-size: 44px; font-weight: 100">
					平台优势
				</div>
				<div>Platform advantages</div>
			</div> -->
      <div class="superiority-list">
        <div>
          <div class="superiority-progress">
            <img
              src="@/assets/img/superiority_Icon1.png"
              alt=""
              style="width: 106px; height: 106px"
            />
            <!-- <el-progress :width="86" :height="86" :stroke-width="4" :show-text="false" type="circle" :percentage="25"></el-progress> -->
          </div>
          <div class="superiority-title">唯一官方平台</div>
          <div class="superiority-text">
            江苏文交所平台具备官方公信力<br />
            作为江苏唯一的省级文化类产权<br />
            投融资综合交易平台
          </div>
        </div>
        <div>
          <div class="superiority-progress">
            <img
              src="@/assets/img/superiority_Icon2.png"
              alt=""
              style="width: 106px; height: 106px"
            />
            <!-- <el-progress :width="86" :height="86" :stroke-width="4" :show-text="false" type="circle" :percentage="50"></el-progress> -->
          </div>
          <div class="superiority-title">IP全产业链</div>
          <div class="superiority-text">
            整合全产品链的“开发交易"和“市场<br />交易”，从开发到授权再到销售，全渠道联<br />合营销，实现一站式P只是产权交易服务
          </div>
        </div>
        <div>
          <div class="superiority-progress">
            <img
              src="@/assets/img/superiority_Icon3.png"
              alt=""
              style="width: 106px; height: 106px"
            />
            <!-- <el-progress :width="86" :height="86" :stroke-width="4" :show-text="false" type="circle" :percentage="75"></el-progress> -->
          </div>
          <div class="superiority-title">海量历史文化资源</div>
          <div class="superiority-text">
            拥有国内众多政府文旅部门，海量文博<br />机构,考古机构的资源素材与IP版权。<br />海量客户资源，精准匹配洽谈
          </div>
        </div>
        <div>
          <div class="superiority-progress">
            <img
              src="@/assets/img/superiority_Icon4.png"
              alt=""
              style="width: 106px; height: 106px"
            />
            <!-- <el-progress :width="86" :height="86" :stroke-width="4" :show-text="false" type="circle" :percentage="100"></el-progress> -->
          </div>
          <div class="superiority-title">专业配套服务</div>
          <div class="superiority-text">
            基地在政府有关部门的指导下，成立了<br />一支专业的服务团队。并拥有投资机构<br />与基金，提供金融配套服务;
          </div>
        </div>
      </div>
    </div>
    <!--平台优势 结束-->
  </div>
</template>

<script>
export default {
  async created() {
    const that = this;
    const { data } = await this.$api({
      c: "index",
      a: "index",
    });

    const arr = data.arr;
    if (arr.banner) {
      that.bannerList = arr.banner;
    }
    if (arr.school) {
      that.collegeList = arr.school;
    }
    if (arr.article != "") {
      that.newsList = arr.article;
      // that.newsList = arr.article.slice(1);
      // that.newsList_first = arr.article[0];
    }
    if (arr.IP) {
      that.sucaiList = arr.IP;
    }
    if (arr.jy) {
      that.HomeList = arr.jy;
    }
    if (arr.jy_banner) {
      that.HomeListBanner = arr.jy_banner;
    }
    if (arr.hd_banner) {
      that.footerListBann = arr.hd_banner;
    }
    //console.log(arr.activity_banner);
    if (arr.activity_banner) {
      that.activityList = arr.activity_banner;
    }
    //console.log(1111111111111111);
    that.first_image = arr.re[0].image;
    that.two_image = arr.re[1].image;
    //服务内容 背景图替换
    // document.getElementById("first_img").style.backgroundImage = "url(http://bqss-oss.oss-accelerate.aliyuncs.com/ipjy/imgs/20210601/1622513304734.png)"
    // document.getElementsByClassName("seek-two")[0].style.backgroundImage = "url(" + arr.re[1].image + ")";
    // document.getElementById("three_img").style.backgroundImage =
    // 	"url(" + arr.re[2].image + ")";
    //console.log(22222222222222);
  },
  name: "Home",
  data() {
    return {
      footerListBann: [
        {
          image: require("@/assets/img/shipin_02.png"),
        },
        {
          image: require("@/assets/img/shipin_02.png"),
        },
        {
          image: require("@/assets/img/shipin_02.png"),
        },
      ],
      server_img: [
        {
          image: require("@/assets/img/service_img_01.png"),
        },
        {
          image: require("@/assets/img/service_img_02.png"),
        },
        {
          image: require("@/assets/img/service_img_03.png"),
        },
        {
          image: require("@/assets/img/service_img_04.png"),
        },
      ],
      bannerList: [],
      newsList_first: {
        today: "",
        year: "",
      },
      activityList: [],
      newsList: [],
      sucaiList: [],
      collegeList: [],
      recommendSelect: 1,
      HomeListBanner: [],
      HomeList: [],
      first_image: "",
      two_image: "",
    };
  },
  methods: {
    Gomaterial() {
      this.$router.push("/index/material");
    },
    GoIPstorage() {
      this.$router.push("/index/IPindex");
      // this.$router.push("/index/IPstorage");
    },
    Goservice() {
      this.$router.push("/index/service");
    },
    huodong() {
      //活动
      this.$router.push("/index/EventPage");
    },
    NEWList() {
      //中心动态
      this.$router.push("/index/news/list");
    },
    newDetail(e) {
      this.$router.push("/index/news/detali?id=" + e.id);
    },
    schoolDetail(id) {
      //console.log(id);
      this.$router.push("/index/school_detail?id=" + id);
    },
    bannerLink(item) {
      //console.log(item.link);
      if (item.link) {
        var links = item.link.split("#");
        //console.log(links[1]);
        this.$router.push(links[1]);
      }
    },
    sucaiDetail(e) {
      // var imgUrl = this.sucaiList[index].img;
      // var title = this.sucaiList[index].title;
      if (e.is_type == 1) {
        this.$router.push({
          path: "/index/IPindexDetail",
          query: {
            id: e.id,
          },
        });
      } else if (e.is_type == 2) {
        this.$router.push({
          path: "/index/Materialdetalis",
          query: {
            id: e.id,
          },
        });
      }
    },
    guapaiDetail(e) {
      this.$router.push({
        path: "/index/informationdetali",
        query: {
          id: e.id,
        },
      });
    },
    guapai() {
      this.$router.push("/index/information/directory");
    },
    async tuijiantab(e) {
      this.recommendSelect = e;
      const { data } = await this.$api({
        c: "index",
        a: "recommend_sucai",
        type: e,
      });
      this.sucaiList = data.arr;
    },
  },
};
</script>

<style lang="scss">
// .el-carousel__indicators--horizontal
.home {
  .home-topbanner {
    .el-carousel__indicators--horizontal {
      left: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 4%;
      top: 50%;
      transform: translate(-100%, -50%);
      // transform: translateY();
    }

    .el-carousel__indicator--horizontal {
      padding: 8px 4px;
      display: block;
      display: flex;

      justify-content: center;
    }

    .el-progress-circle {
      width: 86px !important;
      height: 86px !important;
    }

    .el-carousel__button {
      opacity: 1;
      width: 8px;
      height: 8px;
      // border: 2px solid rgb(7, 88, 227);

      border-radius: 50%;
    }

    .el-carousel__indicator.is-active button {
      width: 12px;
      height: 12px;
    }

    .el-carousel__arrow {
      display: none !important;
    }
  }

  .home-content {
    .el-carousel__indicators--horizontal {
      display: none !important;
    }
  }

  .home-college {
    margin-bottom: 70px;

    .el-carousel__arrow--left {
      height: 72px;
      width: 72px;

      i {
        transform: scale(2);
      }
    }

    .el-carousel__arrow--right {
      height: 72px;
      width: 72px;

      i {
        transform: scale(2);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.banner-img {
  width: 100%;
  // height: 100%;
}

.banner-content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: 100% 100%;
  color: #ffffff;
  position: relative;

  .banner-content-item {
    position: absolute;
    left: 36%;
    top: 48%;
    transform: translate(-50%, -50%);
    z-index: 1;

    h1 {
      margin-bottom: 8px;
    }
  }
}

.content-left {
  padding: 9.5px 0;
  // background-color: #e5e5e8;

  img {
    transform: translateX(9px);
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.content-right {
  padding: 10px 8px 10px 8px;
  z-index: 100;
  width: 500px;
  background-color: #f8f8f8;
  color: #666666;
  transform: translateX(-12.5px);
  margin-top: -15px;
}

.home-content-item {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 8px 16px;
  cursor: pointer;
}

.home-content {
  padding: 24px 0 27px;
}

.home-superiority {
  padding: 60px 0px 60px;
  background-color: #ffffff;
}

.superiority-list {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  //
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // grid-column-gap: 78px;
}

.superiority-progress {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.superiority-title {
  font-size: 24px;
  text-align: center;
  color: #454545;
  margin-top: 100px;
  margin-bottom: 10px;
}

.superiority-text {
  text-align: center;
  font-size: 12px;
  color: #737373;
  white-space: pre-wrap;
  line-height: 20px;
}

.home-seek {
  // padding: 30px 0;
  width: 1200px;
  margin: 0 auto;

  .seek-first {
    background: url("../../assets/img/seek_Icon1.png") no-repeat;
    background-size: cover;
    transition: all 0.6s;
    margin-right: 45px;
    position: relative;

    .seek-item-title {
      font-size: 36px;
      margin-bottom: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  .seek-first:hover {
    transform: scale(1.05);
  }

  // .seek-first:hover {
  // 	position: relative;

  // 	// &::after {
  // 	// 	position: absolute;
  // 	// 	content: "";
  // 	// 	width: 100%;
  // 	// 	left: 0;
  // 	// 	top: 0;
  // 	// 	height: 100%;
  // 	// 	background: rgba(0, 118, 254, 0.8);
  // 	// }
  // }

  .seek-two {
    background: url("../../assets/img/seek_Icon2.png") no-repeat;
    background-size: cover;
    transition: all 0.6s;
    margin-right: 45px;
    position: relative;

    .seek-item-title {
      font-size: 36px;
      margin-bottom: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
  .seek-two:hover {
    transform: scale(1.05);
  }
  // .seek-two:hover {
  // 	position: relative;

  // 	&::after {
  // 		position: absolute;
  // 		content: "";
  // 		width: 100%;
  // 		left: 0;
  // 		top: 0;
  // 		height: 100%;
  // 		background: rgba(0, 118, 254, 0.8);
  // 	}
  // }

  // .seek-three:hover {
  // 	position: relative;

  // 	// &::after {
  // 	// 	transition: all 0.6s;
  // 	// 	position: absolute;
  // 	// 	content: "";
  // 	// 	width: 100%;
  // 	// 	left: 0;
  // 	// 	top: 0;
  // 	// 	height: 100%;
  // 	// 	background: rgba(0, 118, 254, 0.8);
  // 	// }
  // }

  .seek-three {
    transition: all 0.6s;
    background: url("../../assets/img/seek_Icon3.png") no-repeat;
    background-size: cover;
    margin-left: 45px;
    position: relative;

    .seek-item-title {
      font-size: 36px;
      margin-bottom: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
}

.seek-item {
  width: 720px;
  height: 300px;
  padding: 17px 23px 11px 16.5px;
  color: #ffffff;
  border-radius: 10px;

  .seek-item-num {
    // transform: rotate(-90deg);
    margin-bottom: 20px;
  }

  .seek-item-title {
    font-size: 49px;
    margin-bottom: 10px;
  }

  .seek-item-Eng {
    font-size: 16px;
    border-bottom: 1px solid #ffffff;
    padding-right: 10px;
    margin-bottom: 25px;
    padding-bottom: 6.5px;
  }

  .seek-item-content {
    font-size: 22px;
    line-height: 35px;
    white-space: normal;
  }

  .seek-item-button {
    font-size: 16px;
    border: 1px solid #ffffff;
    padding: 18px 44px;
    z-index: 2;
  }

  .seek-item-button:hover {
    font-size: 20px;
    border: 1px solid #ffffff;
    padding: 18px 44px;
    z-index: 2;
  }
}

.home-recommend {
  // background-color: #f8f8f8;
  padding: 0px 0 46px;

  .recommend-item {
    transition: all 0.32s;
    margin-right: 11px;
    background-color: #ffffff;
    text-align: center;
    border-bottom: 2px solid #f8f8f8;
    border-right: 2px solid #f8f8f8;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    img {
      width: 288px;
      height: 288px;
      object-fit: cover;
      border-radius: 10px;
    }

    div {
      padding: 12px 0;
    }
  }

  .recommend-item:hover {
    border-bottom: 2px solid #0076fe;
    border-right: 2px solid #0076fe;
  }
}

.recommend-tabs {
  margin-bottom: 22px;

  .center {
    width: 82px;
    height: 30px;
    color: #212121;
    margin-right: 12px;
  }

  .recommend-tabs-select {
    color: #ffffff;
    background-color: #0076fe;
  }
}

.home-college {
  background-color: #ffffff;
  padding: 20px 0px 32px;
}

.college-List {
  width: 1200px;
  display: flex;
  justify-content: space-between;

  // display: grid;
  // grid-template-columns: 210px 210px 210px 210px;
  // grid-column-gap: 70px;
  text-align: center;
  margin-bottom: 46px;
}

.college-title {
  font-size: 28px;
  color: #454545;
  margin: 180px 0 10px;
}

.college-content {
  white-space: normal;
  font-size: 12px;
  color: #737373;
}

.college-footer {
  width: 120px;
  height: 32px;
  border-radius: 100px;
  color: #0076fe;
  border: 1px solid #0076fe;
}

.college-progress-img {
  position: relative;
  transition: all 0.6s;
  .college-progress {
    width: 126px;
    height: 126px;
    border-radius: 50%;
    background-color: #0076fe;
    text-align: center;
    object-fit: cover;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;

    img {
      width: 122px;
      height: 126px;
      border-radius: 50%;
      margin-left: -3px;
    }
  }
}
.college-progress-img:hover {
  transform: scale(1.05);
}

.home-news {
  background-color: #ffffff;
  padding: 0px 200px 70px;
}

// .activity-border {
// 	width: 100%;
// 	height: 360px;
// 	padding: 60px 130px;
// 	border-right: 2px solid #d1c7b7;
// 	border-bottom: 2px solid #d1c7b7;
// 	border-top-right-radius: 10px;
// 	border-bottom-right-radius: 10px;
// 	border-bottom-left-radius: 10px;
// }

// .activity-border:hover {
// 	border-top-left-radius: 10px;
// 	border: 2px solid #0076fe;
// }

.news-right-time {
  padding: 7px 0px 7px 13px;
  cursor: pointer;
  border-left: 4px solid #0076fe;
  background-color: #ffffff;

  .center-noflex {
    margin-right: 30px;
  }
}

.news-right-arrow {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #666666;
  margin-right: 8px;
}

.newsitem1 {
  padding: 10px 0px;
  border-bottom: 1px solid #0076fe;
  font-size: 12px;
  cursor: pointer;
}

.newsitem {
  padding: 10px 0px 10px 17px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 12px;
  cursor: pointer;
}

.newsitem:hover {
  border-bottom: 1px solid #0076fe;
}

.news-left {
  width: 900px;

  // img {
  // 	width: 400px;
  // 	height: 100%;
  // }
}

.banner-content-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-progress-circle {
  width: 96px !important;
  height: 96px !important;
}
</style>
